import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from '../auth/auth-guard/auth-guard.guard';
import { Role } from '../shared/models/role.model';
import { JobsComponent } from './components/jobs/jobs.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { AdminJobDetailsComponent } from './components/admin-job-details/admin-job-details.component';
import { AdminFaqsComponent } from './components/admin-faqs/admin-faqs.component';

const routes: Routes = [
  { path: 'admin/users', component : UsersComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]} },
  { path: 'admin/faqs', component: AdminFaqsComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]} },
  { path: 'admin/jobs', component : JobsComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]} },
  { path: 'admin/jobs/:ID', component : AdminJobDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]} },
  { path: 'admin/transactions', component : TransactionsComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
