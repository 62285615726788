import { FAQ } from './../../../shared/Interfaces/FAQ';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../core/services/api.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';

/**
 * The Admin FAQs component allows the admin to View, Create, Update and Delete FAQs
 */
@Component({
  selector: 'app-admin-faqs',
  templateUrl: './admin-faqs.component.html',
  styleUrls: ['./admin-faqs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminFaqsComponent implements OnInit {
  /**
   * Rich Text Editor for creating FAQs
   */
  editor = '';
  /**
   * Form for creating an FAQ
   */
  faqForm: FormGroup;
  /**
   * Form for editing an FAQ
   */
  editFaqForm: FormGroup;
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Stores the FAQs fetched through the API
   */
  faqs: FAQ[] = [];
  /**
   * Used to display spinner when waiting for data to be fetched from the API
   */
  fetchingFaqs: boolean;
  /**
   * Stores the ID of the FAQ being edited
   */
  selectedFaqID: string;
  /**
   * State of Mat Expansion Panel
   */
  panelOpenState: boolean;
  /**
   * Modal for creating a FAQ
   */
  @ViewChild('modalCreateFAQ', { static: true }) modalCreateFAQ: TemplateRef<any>;
  /**
   * Modal for editing a FAQ
   */
  @ViewChild('modalEditFAQ', { static: true }) modalEditFAQ: TemplateRef<any>;
  /**
   * Modal for confirmation of deleting a FAQ
   */
  @ViewChild('modalConfirm', { static: true }) modalConfirm: TemplateRef<any>;

  /**
   * Adds dependencies to the component
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param modal - NgbModal
   * @param authService - Authentication Service that contains the authentication information
   */
  constructor(
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private authService: AuthenticationService
  ) { }

  /**
   * Fetches FAQs data when component is initilized
   * Initializes the forms with the default value
   */
  ngOnInit(): void {
    this.fetchFaqs();
    this.faqForm = this.formBuilder.group({
      question: ['', Validators.required],
    });
    this.editFaqForm = this.formBuilder.group({
      question: ['', Validators.required],
    });
  }

  modelChangeFn(e: any) {
    this.editor = e;
  }


  /**
   * Getter for all the faqForm form controls
   */
  get f(): FormGroup['controls'] {
    return this.faqForm.controls;
  }

  /**
   * Gets all the FAQs from the database through the API
   */
  fetchFaqs(): void {
    this.fetchingFaqs = true;
    this.faqs = [];

    this.api.get('/faqs/').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.faqs = res.detail;
          this.fetchingFaqs = false;
        } else {
          this.notify.showError(res.detail);
          this.fetchingFaqs = false;
        }
      },
      error: (e) => {
        this.notify.showError(e);
        this.fetchingFaqs = false;
      }
    });
  }

  /**
   * Displays a modal to create an FAQ
   */
  toggleCreateFaqModal(): void {
    this.modal.open(this.modalCreateFAQ, { size: 'lg', centered: true });
  }

  /**
   * Creates an FAQ through the API endpoint
   */
  createFaq(): void {
    this.submitted = true;

    if (this.faqForm.invalid) {
      return;
    }

    const faq = {
      question: this.faqForm.get('question')?.value,
      answer: this.editor,
    };

    this.api.post('/faqs/admin-create-faqs', JSON.parse(JSON.stringify(faq))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.faqForm.reset();
          this.editor = '';
          this.submitted = false;
          this.fetchFaqs();
          this.notify.showSuccess('FAQ created successfully');
        } else {
          this.notify.showError(res.detail);
        }
      },
      error: (e) => {
        this.notify.showError(e);
      }
    });
    this.modal.dismissAll(this.modalCreateFAQ);

  }

  /**
   * Displays a modal to delete an FAQ
   * @param id - the ID of the FAQ to be deleted
   */
  onDelete(id: string): void {
    this.selectedFaqID = id;
    this.modal.open(this.modalConfirm, { size: 'md', centered: true });
  }

  /**
   * Deletes the selected FAQ through the API endpoint
   */
  deleteFaq(): void {
    const faq = {
      id: this.selectedFaqID
    };

    this.api.delete('/faqs/admin-delete-faqs', JSON.parse(JSON.stringify(faq))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.fetchFaqs();
          this.notify.showSuccess(res.detail);
          this.modal.dismissAll(this.modalConfirm);
        } else {
          this.notify.showError(res.detail);
        }
      },
      error: (e) => {
        this.notify.showError(e);
      }
    });
  }

  /**
   * Displays a modal to edit an FAQ
   * @param faq - The FAQ being edited
   */
  editFaq(faq: FAQ): void {
    this.editFaqForm.get('question')?.setValue(faq.question);
    this.editor = faq.answer;
    this.selectedFaqID = faq._id;
    this.modal.open(this.modalEditFAQ, { size: 'lg', centered: true });
  }

  /**
   * Updates the selected FAQ through the API endpoint
   */
  update(): void {
    const faq = {
      user_id: this.authService.userID,
      faq: {
        _id: this.selectedFaqID,
        question: this.editFaqForm.get('question')?.value,
        answer: this.editor,
        visible: 'True'
      }
    };

    this.api.put('/faqs/admin-update-faqs', JSON.parse(JSON.stringify(faq))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.fetchFaqs();
        } else {
          this.notify.showError(res.detail);
        }
      },
      error: (e) => {
        this.notify.showError(e);
      }
    });

    this.modal.dismissAll(this.modalEditFAQ);
  }

}
