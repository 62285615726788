<div class="container">
    <div class="faqs">
        <div class="buttons">
            <button type="button" class="btn btn-info" (click)="toggleCreateFaqModal()">CREATE FAQ</button>
        </div>
        <div class="d-flex justify-content-center" *ngIf="fetchingFaqs">
            <mat-spinner diameter="20" style="margin-top: 20px; margin-bottom: 20px;"></mat-spinner>
        </div>
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                *ngFor="let faq of faqs">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{faq.question}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHtml]="faq.answer"></div>
                <mat-action-row>
                    <button class="btn btn-info" (click)="editFaq(faq)">EDIT FAQ</button>
                    <button class="btn btn-info" (click)="onDelete(faq._id)">DELETE FAQ</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>

        <!-- CREATE FAQ MODAL -->
        <ng-template #modalCreateFAQ let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Create FAQ</h5>
            </div>
            <div class="modal-body">

                <form [formGroup]="faqForm">
                    <div class="form-group mb-3">
                        <label for="question">Question</label>
                        <input type="text" class="form-control" formControlName="question"
                            [ngClass]="{ 'is-invalid': submitted && f['question'].errors}" autofocus>
                        <div *ngIf="submitted && f['question'].errors" class="invalid-feedback">
                            <div *ngIf="f['question']['errors']required">Question is required</div>
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <label for="answer">Answer</label>
                    </div>
                </form>
                <editor [(ngModel)]="editor" (ngModelChange)="modelChangeFn($event)" [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',
                    plugins: 'lists link image table wordcount'
                  }">
                </editor>
            </div>
            <div class="modal-footer">
                <button class="btn btn-info" style="margin-right: 20px;" (click)="close()">CLOSE</button>
                <button class="btn btn-info" (click)="createFaq()">CREATE FAQ</button>
            </div>
        </ng-template>

        <!-- EDIT FAQ MODAL -->
        <ng-template #modalEditFAQ let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Create FAQ</h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="editFaqForm">
                    <div class="form-group">
                        <label for="question">Question</label>
                        <input type="text" class="form-control" formControlName="question"
                            [ngClass]="{ 'is-invalid': submitted && f['question'].errors}" autofocus>
                        <div *ngIf="submitted && f['question'].errors" class="invalid-feedback">
                            <div *ngIf="f['question']['errors']required">Question is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="answer">Answer</label>
                    </div>
                </form>
                <editor [(ngModel)]="editor" (ngModelChange)="modelChangeFn($event)" [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',
                    plugins: 'lists link image table wordcount'
                  }">
                </editor>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" (click)="close()">CLOSE</button>
                <button type="button" class="btn btn-info" (click)="update()">EDIT FAQ</button>
            </div>
        </ng-template>

        <!-- DELETE FAQ CONFIRMATION MODAL -->
        <ng-template #modalConfirm let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Confirm FAQ Deletion</h5>
            </div>
            <div class="modal-body">
                <div class="update">
                    <p>Are you sure you want to delete this Faq?</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" (click)="close()">NO</button>
                <button type="button" class="btn btn-info" (click)="deleteFaq()">YES</button>
            </div>
        </ng-template>
    </div>
</div>