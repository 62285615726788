<div class="container">
    <button type="button" class="btn btn-info mb-3" (click)="deleteAllJobs()">DELETE ALL JOBS
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <div class="d-flex justify-content-center" *ngIf="isLoading2">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!jobsAvailable && !isLoading2">
        <h2>UH OH!</h2>
        <p> There are no jobs yet.</p>
    </div>
    <div *ngIf="jobsAvailable && !isLoading2">
        <div class="card" *ngFor="let job of jobs | paginate : jobsPagingConfig; let i = index">
            <div class="card-body">
                <h5 class="card-title" (click)="displayJobDetails(job._id)">{{job.title}}</h5>
                <p class="card-text">{{job.description}}</p>
                <p class="card-text"><strong>Skills: </strong>{{job.skills}}</p>
                <div class="row">
                    <div class="col-md-2">
                        <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Budget: </strong>{{(job.budget).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Kaziflex: </strong>{{(job.kaziflex_amount).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Freelancer: </strong>{{(job.freelancer_amount).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Duration: </strong>{{job.duration}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <p class="card-text"><strong>Applicants: </strong>{{job.applicants.length}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center paginator">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onJobsDataChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>