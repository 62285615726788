<div class="container">
    <button type="button" class="btn btn-info mb-3" (click)="deleteAllTransactions()">DELETE ALL TRANSACTIONS
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <div class="d-flex justify-content-center" *ngIf="isLoading2">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!transactionsAvailable && !isLoading2">
        <h2>UH OH!</h2>
        <p> There are no Transactions yet.</p>
    </div>
    <div *ngIf="transactionsAvailable && !isLoading2">
        <div class="card" *ngFor="let transaction of transactions | paginate : transactionsPagingConfig; let i = index">
            <div class="card-body">
                <h5 class="card-title" [ngClass]="{'text-success': transaction.successful, 'text-danger': !transaction.successful}" (click)="displayJobDetails(transaction.job)">{{transaction.product}}</h5>
                <div class="row">
                    <div class="col-md-2">
                        <p class="card-text"><strong>Date: </strong>{{transaction.created_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-text"><strong>Amount Paid: </strong>{{(transaction.amount).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-4">
                        <p class="card-text"><strong>Freelancer Amount: </strong>{{(transaction.freelancer_amount).toFixed(2)}} USD</p>
                    </div>
                    <div class="col-md-4">
                        <p class="card-text"><strong>Kaziflex Amount: </strong>{{(transaction.kaziflex_amount).toFixed(2)}} USD</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center paginator">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTransactionsDataChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>