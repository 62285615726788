import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Transaction } from '../../../shared/Interfaces/Transaction';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit{
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading: boolean;
  /**
   * Variable to store the client's jobs
   */
  transactions: Transaction[] = [];
  /**
   * Used to check if there are any transactions
   */
  transactionsAvailable: boolean;
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading2: boolean;

  transactionsPagingConfig: PagingConfig = {} as PagingConfig;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private router: Router,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.fetchTransactions();
    this.transactionsPagingConfig = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0
    }
  }

  fetchTransactions() {
    this.isLoading2 = true;
    this.transactions = [];
    this.api.get('/transactions/').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.transactionsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.transactionsAvailable = true;
            this.transactions = res.detail;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading2 = false;
      },
      error: (e) => {
        this.isLoading2 = false;
        console.error(e);
      }
    }); 
  }

  deleteAllTransactions(): void {
    this.isLoading = true;
    this.api.get('/transactions/express_removal').subscribe({
      next: res => {
        this.notify.showSuccess(res.detail);
        this.isLoading = false;
        this.fetchTransactions();
      },
      error: (e) => {
        console.error(e);
        this.notify.showError(e);
        this.isLoading = false;
      }
    }); 
  }

  /**
   * Detects if the data in the transactions has changed
   * @param page - The page that has been selected
   */
  onTransactionsDataChange(event: any): void{
    this.transactionsPagingConfig.currentPage = event;
    this.fetchTransactions();
  }

  /**
   * Redirects to the job-details component
   * @param ID - The ID of the selected job
   * Stores job details in browser state
   */
  displayJobDetails(ID: string): void {
    this.router.navigate([`admin/jobs/${ID}`]);
  }
}
