import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { UsersComponent } from './components/users/users.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { AdminJobDetailsComponent } from './components/admin-job-details/admin-job-details.component';
import { AdminFaqsComponent } from './components/admin-faqs/admin-faqs.component';


@NgModule({
  declarations: [
    UsersComponent,
    JobsComponent,
    TransactionsComponent,
    AdminJobDetailsComponent,
    AdminFaqsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    EditorModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class AdminModule { }
