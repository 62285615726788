import { User } from '../../../shared/Interfaces/User';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
  /**
   * Icon displayed on the edit primary role and assign roles columns
   */
  editIcon = faEdit;
  /**
   * Stores values used for filtering
   */
  filterValues: { [key: string]: string } = {};
  /**
   * Stores the columns to be used for filtering
   */
  filterSelectObj:{ name: string, columnProp: string, options: any[], modelValue: any }[] = [];
  /**
   * Icon displayed on the view user details column
   */
  viewIcon = faEye;
  /**
   * Form with input fields for creating a new user
   */
  userForm: FormGroup;
  /**
   * Form with input fields for sending an email
   */
  emailForm: FormGroup;
  /**
   * Form with input field for setting the user's primary role
   */
  editUserForm: FormGroup;
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Stores details of the selected user when the view user details icon is clicked
   */
  selectedUser: User;
  /**
   * Input field for selecting the roles assigned to a user
   */
  roles: FormControl = new FormControl();
  /**
   * Stores the roles of the user when editing their primary role or assigning roles
   */
  userRoles: string[] = ['admin', 'client', 'freelancer'];
  /**
   * Stores the ID of the user when editing their primary role or assigning roles
   */
  ID: string;
  /**
   * Used to display spinner when waiting for data to be fetched from the API
   */
  isLoading = false;
  /**
   * Used to disable button and display spinner when approve users button is clicked
   */
  isLoading1 = false;
  /**
   * Used to disable button and display spinner when delete users button is clicked
   */
  isLoading2 = false;
  /**
   * Used to disable button and display spinner when delete ALL users button is clicked
   */
  isLoading3 = false;
  /**
   * Used to disable button and display spinner when delete ALL users button is clicked
   */
  isLoading4 = false;
  /**
   * Used to disable button and display spinner when sending an email to users
   */
  sendEmailLoading = false;
  /**
   * Used to display spinner when waiting for data to be fetched from the API
   */
  fetchingUsers = true;
  /**
   * Used for selecting multiple records on the table
   */
  selection = new SelectionModel<any> (true, []);
  /**
   * Stores users data that is displayed on the table
   */
  dataSource = new MatTableDataSource<any>();
  /**
   * Stores the columns to be displayed on the table
   */
  displayedColumns: string[] = [
    'select',
    'fullname',
    'email',
    'role',
    'last_active',
    'status',
    'created_At',
    'action'
  ];
  /**
   * Displays or hides the filters
   */
  showFilters: boolean;
  /**
   * Pagination for the users table
   */
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  /**
   * Modal for viewing a user's details
   */
  @ViewChild('userDetailsModal', { static: true }) userDetailsModal: TemplateRef<any>;
  /**
   * Modal for creating a new user
   */
  @ViewChild('createUserModal', { static: true }) createUserModal: TemplateRef<any>;
  /**
   * Modal for assigning roles to a user
   */
  @ViewChild('assignRolesModal', { static: true }) assignRolesModal: TemplateRef<any>;
  /**
   * Modal for setting the primary role of the user
   */
  @ViewChild('primaryRoleModal', { static: true }) primaryRoleModal: TemplateRef<any>;
  /**
   * Modal for sending an email to the selected users
   */
  @ViewChild('sendEmailModal', { static: true }) sendEmailModal: TemplateRef<any>;

  /**
   * Adds dependencies to the component and initializes the filter fields
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param modal - NgbModal
   * @param authService - Authentication Service that contains the authentication information
   */
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private authService: AuthenticationService,
    private modal: NgbModal,
  ) {
    // Initializes the filter fields
    this.filterSelectObj = [
      {
        name: 'Status',
        columnProp: 'account_status',
        options: [],
        modelValue: ''
      },
      {
        name: 'Role',
        columnProp: 'role',
        options: [],
        modelValue: ''
      }
    ];
   }

  /**
   * Fetches users when the component is initialized
   * Initializes the paginator of the data table
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.fetchUsers();
    this.userForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(('^[a-z]+$'))]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]],
      role: ['', Validators.required],
      roles: ['', Validators.required]
    });
    this.editUserForm = this.formBuilder.group({
      role: ['', Validators.required],
    });
    this.emailForm = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required]
    });
  }

  /**
   * Getter for all the user form controls
   */
  get f(): FormGroup['controls'] {
    return this.userForm.controls;
  }

  /**
   * Getter for all the email form controls
   */
  get g(): FormGroup['controls'] {
    return this.emailForm.controls;
  }

  /**
   * Fetches all the users from the databse through the API
   */
  fetchUsers(): void {
    this.fetchingUsers = true;
    this.api.get('/users/admin-get-users').subscribe({
      next: (res) => {
        if (res.status_code === 200) {
          this.dataSource.data = res.detail;
          this.fetchingUsers = false;
          this.filterSelectObj.filter((selectObject: { options: string[]; columnProp: string | number; }) => {
            selectObject.options = this.getFilterObject(res.detail, selectObject.columnProp);
          });
        } else {
          this.notify.showError(res.detail);
          this.fetchingUsers = false;
        }
      },
      error: (e) => {
        console.error(e);
        this.fetchingUsers = false;
      }
    });
  }

  /**
   * checks if all the rows are selected
   * @returns - boolean
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = !!this.dataSource && this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * selects or unselects all the rows
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(r => this.selection.select(r));
  }

  /**
   * Get Unique values from columns to build filter
   * @param fullObj - The booking to be filtered
   * @param key - The keys used for filtering
   * @return - Array of the unique values
   */
  getFilterObject(fullObj: any[], key: string | number): string[] {
    const uniqChk: string[] = [];
    fullObj.filter((object) => {
      if (!uniqChk.includes(object[key])) {
        uniqChk.push(object[key]);
      }
      return object;
    });
    return uniqChk;
  }

  /**
   * Called on filter change
   * @param filter - The filter to be used
   * @param event - The filter term
   */
  filterChange(filter: any, event: Event): void {
    this.dataSource.filterPredicate = this.createFilter();
    this.filterValues[filter.columnProp] = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  /**
   * Custom filter method for Angular Material Datatable
   */
  // tslint:disable-next-line: typedef
  createFilter() {
    const filterFunction = (data: any, filter: string): boolean => {
      const searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      const nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          // tslint:disable-next-line: forin
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach((word: string) => {
              if (data[col].toString().toLowerCase().indexOf(word) !== -1 && isFilterSet) {
                found = true;
              }
            });
          }
          return found;
        } else {
          return true;
        }
      };
      return nameSearch();
    };
    return filterFunction;

  }
  /**
   * Approves the selected users
   */
  approveUsers(): void {
    this.isLoading = true;
    const selectedEmails: string[] = [];
    const selectedIds: string[] = [];

    if (this.selection.selected.length === 0) {
      // this.notify.showError('Select at least one user');
      this.isLoading = false;
    }

    if (this.selection.selected.length > 0) {
      this.selection.selected.forEach(selectedUser => {
        selectedEmails.push(selectedUser.email),
        selectedIds.push(selectedUser._id);
      });

      const user = {
        user_id: this.authService.userID,
        ids: selectedIds,
        emails: selectedEmails
      };

      this.api.put('/users/admin-validate-users-accounts', JSON.parse(JSON.stringify(user))).subscribe(
        res => {
          if (res.status_code === 200) {
            this.fetchUsers();
            this.notify.showSuccess(res.detail);
            this.isLoading = false;
          } else {
            this.notify.showError(res.detail);
            this.isLoading = false;
          }
        },
        err => {
          this.isLoading = false;
        }
      );
    }
  }

  /**
   * Deletes the selected users
   */
  deleteUsers(): void {
    this.isLoading1 = true;

    const selectedIds: string[] = [];

    if (this.selection.selected.length === 0) {
      this.notify.showSuccess('Select at least one user');
      this.isLoading1 = false;
    } else {
      this.selection.selected.forEach(selectedUser => {
        selectedIds.push(selectedUser._id);
      });

      const user = {
        user_id: this.authService.userID,
        ids: selectedIds
      };

      this.api.delete('/users/admin-delete-users', JSON.parse(JSON.stringify(user))).subscribe(
        res => {
          if (res.status_code === 200) {
            this.fetchUsers();
            this.selection.selected.length = 0;
            this.notify.showSuccess(res.detail);
            this.isLoading1 = false;
          } else {
            this.notify.showError(res.detail);
            this.isLoading1 = false;
          }

        },
        err => {
          this.isLoading1 = false;
        }
      );
    }
  }

  deleteAllUsers(): void {
    this.isLoading3 = true;
    this.api.get('/users/express_removal').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showInfo(res.detail);
          this.fetchUsers();
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading3 = false;
      },
      error: (e) => {
        console.error(e);
        this.notify.showError(e);
        this.isLoading3 = false;
      }
    }); 
  }

  deleteAllChats(): void {
    this.isLoading4 = true;
    this.api.get('/chats/express_removal').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showInfo(res.detail);
          this.fetchUsers();
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading4 = false;
      },
      error: (e) => {
        console.error(e);
        this.notify.showError(e);
        this.isLoading4 = false;
      }
    }); 
  }

  /**
   * Displays the modal to create a new user
   */
  openUserModal(): void {
    this.modal.open(this.createUserModal, { size: 'md', centered: true });
  }

  /**
   * Displays the modal to send an email
   */
   openEmailModal(): void {
    if (this.selection.selected.length === 0) {
      this.notify.showError('Select at least one user');
      this.isLoading = false;
    }

    if (this.selection.selected.length > 0) {
      this.modal.open(this.sendEmailModal, { size: 'md', centered: true });
    }
  }

  /**
   * Sends Email to selected user(s)
   */
  sendEmail(): void {
    this.sendEmailLoading = true;

    if (this.emailForm.invalid) {
      return;
    }

    const selectedEmails: string [] = [];

    this.selection.selected.forEach(selectedUser => {
      selectedEmails.push(selectedUser.email);
    });

    const data = {
      emails: selectedEmails,
      subject: this.emailForm.get('subject')?.value,
      body: this.emailForm.get('body')?.value,
    };

    this.api.post('/users/send-emails', JSON.parse(JSON.stringify(data))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.sendEmailLoading = false;
          this.emailForm.reset();
          this.modal.dismissAll(this.sendEmailModal);
        } else {
          this.notify.showError(res.detail);
          this.sendEmailLoading = false;
        }
      },
      err => {
        this.notify.showError(err);
        this.sendEmailLoading = false;
      }
    );
  }

  /**
   * Displays a modal that shows all the selected user's details
   * @param user - The selected User
   */
  viewUserDetails(user: User): void {
    this.selectedUser = user;
    this.modal.open(this.userDetailsModal, { size: 'lg', centered: true });
  }

  downloadResume(resume: string, _id: string): void {
    // this.downloadingResume = true;
    this.api.getFile('/users/download-resume/' + _id).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = resume; // Replace with your filename and extension
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        // this.downloadingResume = false;
      }
    )
  }

  /**
   * Makes API call to create a new user through the API endpoint
   */
  createUser(): void {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    this.isLoading2 = true;


    // Creates a new user object
    const newUser = {
      user_id: this.authService.userID,
      firstname: this.userForm.get('firstname')?.value,
      lastname: this.userForm.get('lastname')?.value,
      email: this.userForm.get('email')?.value,
      role: this.userForm.get('role')?.value,
    };

    this.api.post('/users/admin-add-user', JSON.parse(JSON.stringify(newUser))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.fetchUsers();
          this.isLoading2 = false;
          this.userForm.reset();
          this.submitted = false;
          this.notify.showSuccess(res.detail);
        } else {
          this.notify.showError(res.detail);
          this.isLoading2 = false;
        }
      },
      error: (e) => {
        this.notify.showError(e);
        this.isLoading2 = false;
      }
    });
    this.modal.dismissAll(this.createUserModal);
  }

  /**
   * Displays or hides filters
   */
  displayFilters(): void {
    this.showFilters = !this.showFilters;
  }

  /**
   * Resets all the Filters
   */
   resetFilters(): void {
    this.filterValues = {};
    this.filterSelectObj.forEach((value) => {
      value.modelValue = undefined;
    });
    this.dataSource.filter = '';
  }

}
