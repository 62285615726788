import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss'
})
export class JobsComponent implements OnInit{
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading: boolean;
  /**
   * Variable to store the client's jobs
   */
  jobs: Job[] = [];
  /**
   * Used to check if there are any jobs
   */
  jobsAvailable: boolean;
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading2: boolean;

  jobsPagingConfig: PagingConfig = {} as PagingConfig;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private router: Router,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.fetchJobs();
    this.jobsPagingConfig = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0
    }
  }

  fetchJobs() {
    this.isLoading2 = true;
    this.jobs = [];
    this.api.get('/jobs/all').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.jobsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.jobsAvailable = true;
            this.jobs = res.detail;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading2 = false;
      },
      error: (e) => {
        this.isLoading2 = false;
        console.error(e);
      }
    }); 
  }

  deleteAllJobs(): void {
    this.isLoading = true;
    this.api.get('/jobs/express_removal').subscribe({
      next: res => {
        this.notify.showSuccess(res.detail);
        this.isLoading = false;
        this.fetchJobs();
      },
      error: (e) => {
        console.error(e);
        this.notify.showError(e);
        this.isLoading = false;
      }
    }); 
  }

  /**
   * Detects if the data in the transactions has changed
   * @param page - The page that has been selected
   */
  onJobsDataChange(event: any): void{
    this.jobsPagingConfig.currentPage = event;
    this.fetchJobs();
  }

  /**
   * Redirects to the job-details component
   * @param ID - The ID of the selected job
   * Stores job details in browser state
   */
  displayJobDetails(ID: string): void {
    this.router.navigate([`admin/jobs/${ID}`]);
  }
}
